.ivr-brand{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    
    /* width: 18px; */

    position: fixed;

    bottom: calc(var(--padding) /2);
    right: var(--padding);

    fill: var(--hl);
    color:  var(--hl);
    cursor: pointer;
    transition: fill 0.3s var(--ease), transform 0.3s var(--ease);
}

.ivr-brand svg{
    width: 22px;
    /* height: 30px; */
}


@media only screen and (max-width: 916px) {
    .ivr-brand{
        bottom: calc(var(--padding)/2 + 29px);
    }
}
.ivr-brand h1{
    font-size: 14px;
    margin-right: 10px;
    /* writing-mode: vertical-lr;
    transform: rotateZ(180deg); */
    transition: letter-spacing 0.3s var(--ease), color 0.3s var(--ease);
    
}


.ivr-brand:hover{
    fill: var(--ll);
    color:  var(--ll);
    transform: scale(1.05);
}

.ivr-brand:hover h1{
    letter-spacing: 0.3em;
}