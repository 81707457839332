/* @media (prefers-reduced-motion: no-preference) {

} */
.ivr-map-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}