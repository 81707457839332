.ivr-loader{
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    position: fixed;
    left: 0;
    top:0;
    background-color: var(--bg);
}

.ivr-logo{
    fill: var(--hl);
    
    width: 82px;
    animation: fadeIn 1s infinite alternate;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;

}

.ivr-title{
    color: var(--hl);
    opacity: 1;
    animation: fadeIn 1s infinite alternate;
    text-align: center;
}

@keyframes fadeIn { 
    from { opacity: 0; } 
  }