.ivr-about{
    z-index: 500;
    width: 100%;
    height: 100vh;

    position: fixed;
    left: 0;
    top:0;
    background-color: var(--bg);
}

.ivr-about .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(var(--padding) * 2) 0 ;
}

.ivr-about .indent{
    padding-left: calc(var(--padding) * 1);
}

.ivr-about .logo{
    fill: var(--hl);
    color: var(--hl);
    transition: all 0.6s var(--ease);
    cursor: pointer;
}

.ivr-about .logo:hover{
    fill: var(--ll);
    color: var(--ll);
    transform: scale(1.05);
}

.ivr-about .logo:hover svg{
    transform: scale(1.025);
}

.ivr-about .logo:hover h1{
    letter-spacing: 0.2em;
}

.ivr-about .logo svg{
    width: 82px;
    opacity: 1;  
    transition: transform 0.6s var(--ease);  
}

.ivr-about .logo h1{
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: letter-spacing 0.6s var(--ease);  
}

.ivr-about .cross{
    position: absolute;
    stroke: var(--hl);
    width: 24px;
    cursor: pointer;
    right: var(--padding);
    bottom: var(--padding);
    transition: all 0.3s var(--ease);
}

.ivr-about .paragraph{
    max-width: 450px;
    /* text-align: justify; */
    color: var(--hl);
    padding: var(--padding);
    /* width: 100%; */

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

.ivr-about .paragraph svg{
    display: inline;
    width: 0.8em;
    fill: var(--hl);
}

.ivr-about .cross:hover{
    transform: scale(1.2);
    stroke: var(--ll);;
}

.ivr-about a{
    color: var(--ll);
    border-bottom: var(--ll) 1px solid;
}

.ivr-about a:hover{
    color: var(--hl);
    border-color: var(--hl);
}