.nav-wrap,
.nav-filter-wrap,
.nav-detail-wrap,
.nav-check-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
}

.nav-static{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 4;  
}

.nav-static > *{
  pointer-events: all;
}

.nav-wrap {
  width: 100%;
  padding: 0 var(--padding);

  background: var(--bg);
  box-shadow: var(--shadow-01);

  flex-flow: row wrap;
}

.nav-detail-wrap, .nav-filter-wrap{
  padding: calc(var(--padding) /2) 0;
}

.nav-detail-wrap {
  flex: 0 0 auto;
  min-width: calc(var(--pane-width) - var(--padding) *2 );
}

.nav-filter-wrap {
  flex: 1 1;
  justify-content: left;
  margin-right: var(--padding);
}

.nav-checkbox {
  flex: 0 0 auto;
  height: var(--node-size);
  width: var(--node-size);
  
  border-radius: 50%;

  margin: 0 var(--padding);

  box-shadow: var(--shadow-01);
  background-color: var(--hl);
}

.nav-check {
  position: absolute;
  margin: var(--node-stroke);
  width: var(--node-inner);
  height: var(--node-inner);
  border-radius: 50%;
  box-shadow: var(--shadow-01);
  background-color: var(--bg);
  transition: all 0.3s var(--ease);
  transform: scale(0);
}

.nav-checkbox.Led{
  background-color: var(--Led);
}

.nav-checkbox.Collaboration{
  background-color: var(--Collaboration);
}

.nav-checkbox.Partnership{
  background-color: var(--Partnership);
}

.nav-check-wrap{
  cursor: pointer;
}

.nav-check-wrap:hover .nav-checkbox {
  box-shadow: var(--shadow-02);
}

.nav-checked {
  background-color: var(--bg);
  transform: scale(1);
}

.mobile{
  display: none
}

.desktop{
  display: unset
}

@media only screen and (max-width: 524px) {
  .nav-check-wrap:nth-child(2) .nav-checkbox {
    margin-left: 0;
  }

  .desktop{
    display:none
  }

  .mobile{
    display: unset
  }
}
