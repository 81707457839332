:root {
  --fg: #3E4348;
  --bg: #f2f2f2;
  --hl: #548687;
  --ll: #56445D;
  --sll: #8FBC94;;

  --Led: #C5E99B;
  --Partnership: #548687;
  --Collaboration:#56445D;

  --padding: 24px;

  --node-size: 24px;
  --node-stroke: 8px;
  --node-inner: calc(var(--node-size) - (var(--node-stroke) * 2));

  --pane-width: 400px;
  --nav-height: 56px;



  --ease: cubic-bezier(.25,.8,.25,1);
  --shadow-01-i: 1px -1px 3px rgba(0, 0, 0, 0.06), 1px -1px 8px rgba(0, 0, 0, 0.08);
  --shadow-02-i:0 -14px 28px rgba(0,0,0,0.12), 0 -10px 10px rgba(0,0,0,0.06);
  --shadow-01: 0 1px 3px rgba(12, 7, 7, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-02:0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --shadow-01-f: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.22));
  --shadow-02-f: drop-shadow(0 10px 10px rgba(0,0,0,0.46));
  --detail-offset: calc(19px + var(--padding) * 2);
}

@media only screen and (max-width: 916px) {
  :root{
    --pane-width: 100vw;
  }

  .ivr-key{
    margin-top: var(--detail-offset) ;
  }
}

@media only screen and (max-width: 400px) {
  :root{
    --padding: 16px;
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fg);
}

h1{
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 12px;
}

a {
  text-decoration: none;
  color: var(--hl);
  transition: all 0.3s var(--ease);
}

a:hover {
  color: var(--ll);
}

.mapboxgl-ctrl{
  display: none !important;
}
